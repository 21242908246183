<template>
  <h1>hello</h1>
</template>

<script>
import VaChart from "@/components/va-charts/VaChart";

export default {
  name: "Dashboard",

}
</script>

<style scoped>

</style>
